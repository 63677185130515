import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
    <Layout sermonSrc="https://www.youtube.com/embed/6Wlgtv9rwvk">
        <SEO title="Fear - Crossing Rivers" />
    </Layout>
)

export default SermonPost
